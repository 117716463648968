{
  "menu": {
    "home": "Home",
    "recherche": "FAQ",
    "ou": "Where i am ?",
    "horaire": "Opening hours"
  },
  "main": {
    "ordre_alphabetique": "Alphabetical order",
    "ordre_categorie": "Categories order",
    "retour": "Back",
    "returnFAQ": "Back",
    "bebe": "Baby and breastfeeding",
    "tous": "All",
    "filtre": "Filters",
    "chargement": "Loading",
    "navigation": "Adapted navigation",
    "navigationActive": "All audiences menu",
    "Evenements": "Discover",
    "Magasins": "",
    "Loisirs": "",
    "Offres": "",
    "Services": "",
    "Actualies": "",
    "linkArticle": "See more",
    "loisirs": "Hobbies",
    "actu": "News",
    "offre": "Offers",
    "restaurants": "Restaurants",
    "Restaurants": "",
    "boutiques": "Shops",
    "menu": "menu",
    "menuClose": "Close",
    "titreBorne": "Terminal initialization",
    "descriptionBorne": "You must initialize the terminal"
  },
  "singlePopup": {
    "titre": "A problem has been encountered",
    "description": "It seems that this article does not exist in this language"
  },
  "popup": {
    "titre": "A problem has been encountered",
    "description": "Please use another terminal.",
    "lien": "Close"
  },
  "home": {
    "boutique": "Find a <strong>shop</strong>",
    "restaurant": "Choose a <strong>restaurant</strong>",
    "divertir": "To <strong>entertain</strong>",
    "evenements": "Events",
    "offres": "Offers",
    "actualites": "News",
    "cadeau": "Gift Card",
    "services": "Services",
    "transports": "Transports"
  },
  "about": {
    "welcome": "Welcome to the About Balexert page!"
  }
}
