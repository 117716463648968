<link rel="stylesheet" href="../../../../../Users/theo/Downloads/transfonter.org-20210531-075301/stylesheet.css">
<template>
  <Loading v-if="!appLoadFonts && !appLoadImages"/>
  <div class="vue" :class="{opacityBody: !appLoadImages}">
    <Header/>
    <Menu :offline="appIsOffline"/>
    <div v-if="appIsOffline && !popup " class="popup">
      <div class="overlay"></div>
      <div class="content">
        <h2>{{ $t("popup.titre") }}</h2>
        <p>
          {{ $t("popup.description") }}
        </p>
      </div>
    </div>
    <router-view :class="{navigationAdapteBody: navigation}"/>
    <Popup :clicked="false" :title="title" :description="description" v-if="!borneInitialized"/>
  </div>


</template>

<script>
// eslint-disable-next-line no-unused-vars

import Menu from "@/components/Menu";
import {mapState} from 'vuex'

import("./assets/reset.css");
import("./assets/style.css");

import Header from "@/components/Header";
import Popup from "@/components/Layouts/Popup";
import Loading from "@/components/Layouts/Loading";

export default {
  name: 'App',
  components: {
    Loading,
    Popup,
    Menu,
    Header,
  },
  data() {
    return {
      appIsOffline: false,
      popup: false,
      navigation: false,
      borneInitialized: false,
      title: 'main.titreBorne',
      description: 'main.descriptionBorne',
      appLoadFonts: false,
      appLoadImages: false,
      cpt:0,
    }
  },
  methods: {
    initBorne() {
      this.$store.state.navigationAdapte = false;
      if (window.location.search) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get("origin") !== null) {
          this.$store.commit('setIDBorne', urlParams.get("origin"))
          this.$store.commit('setFloorBorne', urlParams.get("floorBorne"))
        }
      }
      if (this.$store.state.borne.idOfBorne === null) {
        this.borneInitialized = false;
      } else {
        this.borneInitialized = true;
      }
    },
    initScriptsVisioGlobe() {
  const visioglobeScripts = [
      '/visioweb.js',
    '/visioweb.essential.js',

  ];

      visioglobeScripts.forEach( script => {

        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        document.head.appendChild(tag);

        tag.onload = () => {
          this.$store.state.scriptLoaded = true;
          this.emitter.emit("scriptLoaded", true);
        }
      });

    },
    checkBornOnline() {
      // Initialise la variable Single pour eviter les doublons de requete quand on switch de langue
      this.$store.state.onSingle = false;
      if (!window.navigator.onLine) {
        this.appIsOffline = false;
      }
      if (navigator.serviceWorker.controller !== null) {
        if (navigator.serviceWorker.controller.state === "activated" && !window.navigator.onLine) {
          this.appIsOffline = true;
          this.$router.push('/')
        }
      }
      window.addEventListener('offline', () => {
        this.appIsOffline = true;
        this.$router.push('/')
      })
      window.addEventListener('online', () => {
        this.appIsOffline = false;
      })
    },
    listenNavigationAdapte() {
      this.emitter.on("navigationAdapte", (e) => {
        if (e) {
          this.navigation = true;
        } else {
          this.navigation = false;
        }
      });
    },
    loadImages() {

      var imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call(imgs, (img) => {
        if (img.complete)
          counter++;
        if (counter === len) {
          this.appLoadImages = true;
        } else
          img.addEventListener('load', () => {
            counter++;
            if (counter === len) {
              this.appLoadImages = true;
            }
          });
      });
    },
    loadFonts() {
      document.fonts.ready
          .then(() => {
            console.log("LOAD FONTS");
            this.appLoadFonts = true;
          })
    }
  },
  computed: mapState({
    idOfBorne: state => state.borne.idOfBorne
  }),
  mounted() {
    this.initBorne();

    this.checkBornOnline();
    this.listenNavigationAdapte();

    this.$store.state.scriptLoaded = true;

    this.emitter.emit("scriptLoaded", true);

    setTimeout(() => {
      this.loadImages();
      this.loadFonts();
    }, 50)
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/general.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px !important;
}
</style>

