const state = {
    idOfBorne: null,
    floorBorne: null,
};

const mutations = {
    setIDBorne(state, ID) {
        state.idOfBorne = ID;
    },
    setFloorBorne(state, ID) {
        state.floorBorne = ID;
    },
};

export default {
    state,
    mutations
}
