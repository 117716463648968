import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import {Route} from "@/utils/variables";
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    component: () => import(/* webpackChunkName: "about" */ '../views/Restaurants.vue')
  },
  {
    path: '/services',
    name: 'Services',
    props: {
      apiRoute: Route.listingServices,
      routeSingle: Route.singleGeneric,
      image: "@/assets/images/categories/banner-actus.jpeg",
      title: "home.services"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ListingGeneric.vue')
  },
  {
    path: '/magasins',
    name: 'Magasins',
    component: () => import(/* webpackChunkName: "about" */ '../views/Magasins.vue')
  },
  { //TODO supprimer route single magasin ?
    path: '/magasins?:id',
    name: 'Magasin',
    props: {
      routeSingle: Route.singleGeneric,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Magasins.vue'),
  },
  {
    path: '/loisirs',
    name: 'Loisirs',
    component: () => import(/* webpackChunkName: "about" */ '../views/Loisirs.vue')
  },
  {
    path: '/recherche',
    name: 'Recherche',
    component: () => import(/* webpackChunkName: "about" */ '../views/Recherche.vue')
  },
  {
    path: '/evenements',
    name: 'Evenements',
    props: {
      apiRoute: Route.listingEvent,
      routeSingle: Route.singleEvent,
      image: "@/assets/images/categories/banner-actus.jpeg",
      title: "home.evenements",
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ListFilterGeneric.vue')
  },
  {
    path: '/ou',
    name: 'Ou',
    props: {
      apiRoute: Route.listingOffres,
      title: "main.ou"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Ou.vue')
  },
  {
    path: '/cadeau',
    name: 'Cadeau',
    props: {
      title: "home.cadeau"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Cadeau.vue')
  },
  {
    path: '/baby',
    name: 'Baby',
    component: () => import(/* webpackChunkName: "about" */ '../views/Baby.vue')
  },
  {
    path: '/transports',
    name: 'Transport',
    component: () => import(/* webpackChunkName: "about" */ '../views/Transport.vue')
  },
  {
    path: '/horaire',
    name: 'Horaire',
    component: () => import(/* webpackChunkName: "about" */ '../views/Horaire.vue')
  },
  {
    path: '/offres',
    name: 'Offres',
    props: {
      apiRoute: Route.listingOffres,
      routeSingle: Route.singleOffre,
      title: "main.offre"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ListFilterGeneric.vue')
  },
  {
    path: '/actualites',
    name: 'Actualites',
    props: {
      apiRoute: Route.listingNews,
      routeSingle: Route.singleNews,
      image: "@/assets/images/categories/banner-actus.jpeg",
      title: "main.actu"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ListFilterGeneric.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
