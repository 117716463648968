<template>
  <header>
    <router-link to="/">
      <img class="logo" @click="disabledSingleBoolean()" alt="Logo balexert" src="@/assets/logo.png" style="margin-bottom: 40px">
    </router-link>
  </header>
</template>

<script>
export default {
  name: "Header",
  methods: {
    disabledSingleBoolean() {
      this.$store.state.onSingle = false
    }
  }
}
</script>

<style scoped>

.logo {
  display: block;
  margin: 0 auto;
  height: auto;
  max-width: 326px;
  margin-bottom: 0!important;
}

</style>
