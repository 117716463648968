<template>
  <div class="popup" v-if="popup">
    <div class="overlay" @click="clicked ? popup = !popup : ''"></div>
    <div class="content">
      <h2>{{ $t(`${this.title}`) }}</h2>
      <p>
        {{ $t(`${this.description}`) }}
      </p>
    </div>
  </div>
</template>
<script>

export default {
  name: "Popup",
  props: {
    title: String,
    description: String,
    clicked: Boolean,
  },
  methods: {
    returnSingle() {
      this.$emit("returnList", true);
      this.error = false;
    },
  },
  data() {
    return {
      popup: true,
    }
  }
}

</script>
