{
  "menu": {
    "home": "Accueil",
    "recherche": "FAQ",
    "ou": "Où suis-je ?",
    "horaire": "Horaires"
  },
  "main": {
    "ordre_alphabetique": "Par ordre alphabétique",
    "ordre_categorie": "Par catégories",
    "retour": "Retour à la liste",
    "returnFAQ": "Retour à la FAQ",
    "chargement": "Chargement",
    "bebe": "Bébé et allaitement",
    "Evenements": "Découvrir",
    "Magasins": "",
    "Loisirs": "",
    "Offres": "",
    "Services": "",
    "Actualies": "",
    "tous": "Tous",
    "filtre": "Filtres",
    "navigation": "Navigation adaptée",
    "navigationActive": "Navigation tout public",
    "linkArticle": "j’en profite",
    "loisirs": "Loisirs",
    "actu": "Actualités",
    "offre": "Offres",
    "restaurants": "Restaurants",
    "Restaurants": "",
    "boutiques": "Boutiques",
    "menu": "menu",
    "menuClose": "Fermer",
    "titreBorne": "Initialisation de la borne",
    "descriptionBorne": "Vous devez initialiser la borne"
  },
  "singlePopup": {
    "titre": "Un problème a été rencontré",
    "description": "Il semblerait que cet article n'existe pas dans cette langue"
  },
  "popup": {
    "titre": "Un problème a été rencontré",
    "description": "Veuillez utiliser une autre borne.",
    "lien": "Fermer"
  },
  "home": {
    "boutique": "Trouver une <strong>boutique</strong>",
    "restaurant": "Choisir un <strong>restaurant</strong>",
    "divertir": "Se <strong>divertir</strong>",
    "evenements": "Événements",
    "offres": "Offres",
    "actualites": "Actualités",
    "cadeau": "Carte Cadeau",
    "services": "Services",
    "transports": "Transports"
  },
  "about": {
    "welcome": "Bienvenue sur la page A propos de Balexert !"
  }
}
