export const API_URL = 'https://balexert.ch';
export const API = API_URL + '/wp-json/totem/v1/';
export const VISIO_GLOBE_HASH_KEY = 'k6a3e0c44d2728dff51a76f6b2fff5e605f72e2eb';
export const Route = {
    singleGeneric: '/shop/',
    listingEvent: '/event/list',
    singleEvent: "/event/",
    listingOffres: '/offer/list',
    singleOffre : '/offer/',
    listingServices: '/shop/list/services',
    listingNews: '/news/list',
    singleService: '/news/list',
    singleNews : '/news/',
    singleHoraire : '/timetable/',
}

