import { createStore } from "vuex"
import borne from './modules/borne'
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state:{
        onSingle: false,
        navigationAdapte: false,
        scriptLoaded: false,
    },
    modules: {
        borne,
    },
    plugins: [createPersistedState()]
})

export default store
